/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-autoImg {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
}
.app-autoImg .toolbar-preview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background: #000000;
  opacity: 0.7;
  color: #2d63ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-autoImg .autoImg-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-autoImg .autoImg-error .autoImg-error-text {
  width: 80%;
  max-width: 100px;
  pointer-events: none;
}
