/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@tailwind components;
@tailwind utilities;
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #2d63ff;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2d63ff, 0 0 5px #2d63ff;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #2d63ff;
  border-left-color: #2d63ff;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#nprogress .bar {
  background: #fff627 !important;
}
#nprogress .peg {
  box-shadow: unset !important;
}
@font-face {
  font-family: DIN-Bold;
  src: url('~@/assets/fonts/DIN-Bold.otf') format('opentype');
}
@font-face {
  font-family: DIN-Light;
  src: url('~@/assets/fonts/DIN-Light.otf') format('opentype');
}
/** S BASE **/
body {
  min-width: 980px;
}
#app {
  height: 100%;
}
.content-main-holder {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.content-main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 30px;
  position: relative;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #999;
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  box-shadow: inset 0 0 1px 1px rgba(65, 159, 244, 0.4);
  background: rgba(65, 159, 244, 0.6);
}
/** E BASE **/
/** S REWRITE **/
.ant-message-notice-content {
  word-break: break-all;
}
.has-error .ant-upload-select-picture-card {
  border: 1px dashed #f5222d;
}
.tight.ant-table-wrapper td,
.tight.ant-table-wrapper th {
  padding: 4px !important;
}
.modal-scroll .ant-modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-scroll .ant-modal-content {
  max-height: 100%;
}
.modal-scroll .ant-modal-body {
  max-height: calc(100vh - 280px);
  overflow: auto;
}
@screen sm {
  .modal-scroll .ant-modal-body {
    max-height: calc(100vh - 275px);
  }
}
@screen md {
  .modal-scroll .ant-modal-body {
    max-height: calc(100vh - 270px);
  }
}
@screen lg {
  .modal-scroll .ant-modal-body {
    max-height: calc(100vh - 265px);
  }
}
.modal-scroll.full-screen .ant-modal {
  top: 0;
  height: 100%;
  width: 100% !important;
  margin: 0 !important;
  max-width: 100% !important;
}
.modal-scroll.full-screen .ant-modal-content {
  max-height: calc(100vh) !important;
  border-radius: 0;
}
.modal-scroll.full-screen .ant-modal-body {
  max-height: calc(100vh - 110px) !important;
}
.distpicker-address-wrapper label {
  vertical-align: middle;
}
.distpicker-address-wrapper label select {
  height: 32px !important;
  padding: 0 10px !important;
  font-size: 14px !important;
}
.distpicker-address-wrapper label select option:first-child {
  display: none;
}
/** E REWRITE **/
/** S TOOLS **/
.form-control {
  width: 200px;
  max-width: 100%;
}
.form-control.lg {
  width: 250px;
}
.ant-form .form-control {
  width: 200px;
}
.ant-form .form-control.lg {
  width: 250px;
}
.form-leading {
  line-height: 40px;
}
.checkbox-vertical .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  display: block;
}
.line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.ant-form.form-label-6 .ant-form-item-label {
  width: 7em;
}
.ant-form.form-label-5 .ant-form-item-label {
  width: 6em;
}
.ant-form.form-label-4 .ant-form-item-label {
  width: 5em;
}
.ant-form .form-label-auto .ant-form-item-label {
  width: unset;
}
.ant-form.flex-form .ant-form-item {
  display: flex;
}
.ant-form.flex-form .ant-form-item-label {
  flex: 0 0 auto;
}
.ant-form.flex-form .ant-form-item-control-wrapper {
  flex: 1 1 0;
}
.ant-form.flex-form .ant-form-item-control-wrapper .ant-input-number {
  width: 100%;
}
.ant-form .form-label-left .ant-form-item-label {
  text-align: left !important;
}
.ant-form .form-label-right .ant-form-item-label {
  text-align: right !important;
}
.ant-form.no-gap .ant-form-item {
  margin-bottom: 0;
}
.queryView .queryView-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.queryView .queryView-actions button {
  margin-right: 10px;
}
.queryView .queryView-actions button:last-child {
  margin-right: 0;
}
.queryView .queryView-conditions {
  margin-bottom: 25px;
}
.queryView .queryView-conditions .condition-btns {
  display: inline-block;
  white-space: nowrap;
  padding-left: 8px;
}
.queryView .queryView-conditions .condition-btns .ant-btn {
  margin-left: 10px;
}
.queryView .queryView-main {
  margin-bottom: 20px;
}
.detailView .form-base-width {
  width: 712px;
}
.detailView .detailView-with-actions {
  padding-bottom: 66px;
}
.detailView .detailView-actions {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
  border-top: 1px solid #dfdfdf;
}
.detailView .detailView-actions .ant-btn {
  margin: 0 10px;
  width: 150px;
}
.pageContent-title {
  position: relative;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.pageContent-title:before {
  content: '';
  width: 4px;
  height: 26px;
  position: absolute;
  left: 0px;
  background: #2d63ff;
}
