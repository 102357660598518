/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.auto-img {
  position: relative;
  display: inline-block;
}
.auto-img .auto-img__img {
  display: block;
  width: 100%;
  height: 100%;
}
.auto-img .auto-img__error,
.auto-img .auto-img__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #969799;
  font-size: 14px;
  background-color: #f7f8fa;
}
