@font-face {
  font-family: DIN-Bold;
  src: url('~@/assets/fonts/DIN-Bold.otf') format('opentype');
}

@font-face {
  font-family: DIN-Light;
  src: url('~@/assets/fonts/DIN-Light.otf') format('opentype');
}

/** S BASE **/
// layout
body {
  min-width: 980px;
}
#app {
  height: 100%;
}
.content-main-holder {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.content-main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 30px;
  position: relative;
}
// 滚动条
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #999;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  box-shadow: inset 0 0 1px 1px rgba(65, 159, 244, 0.4);
  background: rgba(65, 159, 244, 0.6);
}
/** E BASE **/

/** S REWRITE **/

.ant-message-notice-content {
  word-break: break-all;
}
// 特定情况下的重写
// 图片上传组件的错误提示
.has-error {
  .ant-upload-select-picture-card {
    border: 1px dashed #f5222d;
  }
}
.tight.ant-table-wrapper {
  td,
  th {
    padding: 4px !important;
  }
}
// modal内部滚动
.modal-scroll {
  .ant-modal {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ant-modal-content {
    max-height: 100%;
  }
  .ant-modal-body {
    max-height: calc(100vh - 280px);
    overflow: auto;
  }
}

@screen sm {
  .modal-scroll {
    .ant-modal-body {
      max-height: calc(100vh - 275px);
    }
  }
}
@screen md {
  .modal-scroll {
    .ant-modal-body {
      max-height: calc(100vh - 270px);
    }
  }
}
@screen lg {
  .modal-scroll {
    .ant-modal-body {
      max-height: calc(100vh - 265px);
    }
  }
}
.modal-scroll.full-screen {
  .ant-modal {
    top: 0;
    height: 100%;
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
  }
  .ant-modal-content {
    max-height: calc(100vh) !important;
    border-radius: 0;
  }
  .ant-modal-body {
    max-height: calc(100vh - 110px) !important;
  }
}

.distpicker-address-wrapper {
  label {
    vertical-align: middle;
    select {
      height: 32px !important;
      padding: 0 10px !important;
      font-size: 14px !important;
      option:first-child {
        display: none;
      }
    }
  }
}
/** E REWRITE **/

/** S TOOLS **/
// 表单控件基础样式 - 主要是宽度

.form-control {
  width: 200px;
  max-width: 100%;
}
.form-control.lg {
  width: 250px;
}
// antd 里面需要提高层级
.ant-form {
  .form-control {
    width: 200px;
  }
  .form-control.lg {
    width: 250px;
  }
}
// form行高
.form-leading {
  line-height: 40px;
}
// 增加竖行checkbox
.checkbox-vertical {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    display: block;
  }
}
// 省略
.line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.ant-form {
  &.form-label-6 {
    .ant-form-item-label {
      width: 7em;
    }
  }
  &.form-label-5 {
    .ant-form-item-label {
      width: 6em;
    }
  }
  &.form-label-4 {
    .ant-form-item-label {
      width: 5em;
    }
  }
  .form-label-auto {
    .ant-form-item-label {
      width: unset;
    }
  }
  &.flex-form {
    .ant-form-item {
      display: flex;
    }
    .ant-form-item-label {
      flex: 0 0 auto;
    }
    .ant-form-item-control-wrapper {
      flex: 1 1 0;
      .ant-input-number {
        width: 100%;
      }
    }
  }
  .form-label-left {
    .ant-form-item-label {
      text-align: left !important;
    }
  }
  .form-label-right {
    .ant-form-item-label {
      text-align: right !important;
    }
  }
  &.no-gap {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.queryView {
  .queryView-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .queryView-conditions {
    margin-bottom: 25px;
    .condition-btns {
      display: inline-block;
      white-space: nowrap;
      padding-left: 8px;
      .ant-btn {
        margin-left: 10px;
      }
    }
  }

  .queryView-main {
    margin-bottom: 20px;
  }
}
.detailView {
  .form-base-width {
    width: 712px;
  }
  .detailView-with-actions {
    padding-bottom: 66px;
  }
  .detailView-actions {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 96px;
    border-top: 1px solid #dfdfdf;
    .ant-btn {
      margin: 0 10px;
      width: 150px;
    }
  }
}
.pageContent-title {
  position: relative;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 20px;
  &:before {
    content: '';
    width: 4px;
    height: 26px;
    position: absolute;
    left: 0px;
    background: @primary-color;
  }
}
