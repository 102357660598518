/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.site-menu {
  overflow: auto;
  background: #fff;
  width: 300px;
  box-shadow: 5px 0px 10px 0px rgba(153, 153, 153, 0.3);
  position: relative;
  z-index: 2;
}
.site-menu .menu-logo {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-menu .menu-logo img {
  width: 200px;
}
.site-menu .menu-level-0 {
  color: #3a3a3a;
  position: relative;
}
.site-menu .menu-level-0.active .title {
  background: #2d63ff;
  color: #fff;
}
.site-menu .menu-level-0 .title {
  padding-left: 52px;
  font-size: 16px;
  line-height: 56px;
  position: relative;
  width: 270px;
  height: 56px;
  border-radius: 0px 28px 28px 0px;
  cursor: pointer;
}
.site-menu .menu-level-0 .title .title-text {
  margin-left: 12px;
}
.site-menu .menu-level-0.has-children .title {
  border-radius: 0px 28px 0 0px;
}
.site-menu .menu-level-0.active .affix-menu-list {
  max-height: unset;
}
.site-menu .menu-level-0 .right-icon {
  position: absolute;
  right: 30px;
}
.site-menu .menu-level-0 .affix-menu-list {
  padding-left: 80px;
  width: 270px;
  max-height: 0;
  background: #fff2f2;
  border-radius: 0px 0px 28px 0px;
  overflow: hidden;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item {
  font-size: 16px;
  line-height: 56px;
  height: 56px;
  position: relative;
  color: #666;
  cursor: pointer;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item .title-text {
  margin-left: 12px;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item > span {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item.active {
  color: #2d63ff;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item:hover {
  color: #2d63ff;
}
.site-menu > .menu-item {
  padding: 0 30px;
  line-height: 55px;
}
